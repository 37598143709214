<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1" style="background-image: url('assets/img/cover.jpg');">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="margin-top: -3%; color: white;">Photo Gallery</h2>
                    <ul>
                        <li style="color: white;">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<section class="mt-50px" style="margin-bottom: 5rem;">
    <div class="container">
        <div class="card" style="border: none;">
            <div class="card-body">
                <div class="tab">
                    <h3 class="mr-5px" style="color: #3F4096;">Year</h3>
                    <button class="rounded-10" (click)="selectTab(1)" [class.active]="selectedTab === 1">2023</button>
                    <button class="rounded-10" (click)="selectTab(2)" [class.active]="selectedTab === 2">2022</button>
                    <button class="rounded-10" (click)="selectTab(3)" [class.active]="selectedTab === 3">2021</button>
                </div>
                <div class="tab-content mt-50px">
                    <div class="container" *ngIf="selectedTab === 1">
                        <h5 class="mt-50px mb-4" style="color: #3F4096;">The Festival Company 2023</h5>
                        <div class="gallery">
                            <!--first section-->

                            <!--second section-->
                            <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-2.jpg"
                                            alt="">
                                    </a>
                                </div>
                            </a>
                            <div class="single-img medium-img">
                                <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                    <img
                                        class="rounded-10 shadow"
                                        src="assets/gallery-2.jpg"
                                        alt="">
                                </a>
                            </div>
                        </div>
                        <h5 class="mt-50px mb-4" style="color: #3F4096;">The Festival Company 2023</h5>
                        <div class="mt-2">
                            <div class="gallery">
                                <!--third section-->
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-2.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img big-img">
                                    <div class="single-img big-img">
                                        <div id="" class="carousel slide w-100"
                                             data-bs-ride="carousel">
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <a #img
                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                        <img
                                                            src="assets/gallery-1.jpg"
                                                            class="rounded-10">
                                                    </a>
                                                </div>
                                                <div class="carousel-item">

                                                    <a #img
                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                        <img
                                                            src="assets/gallery-2.jpg"
                                                            class="rounded-10">
                                                    </a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a #img
                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                        <img
                                                            src="assets/gallery-1.jpg"
                                                            class="rounded-10">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--fourth section-->
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <h5 class="mt-50px mb-4" style="color: #3F4096;">The Festival Company 2023</h5>
                        <div class="mt-2">
                            <div class="gallery">

                                <!--third section-->
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-2.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img big-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>

                                <!--fourth section-->
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                   <!-- <div class="container" *ngIf="selectedTab === 2">
                        <h5 class="mt-50px mb-4" style="color: #3F4096;">The Festival Company 2022</h5>
                        <div class="gallery">
                            &lt;!&ndash;first section&ndash;&gt;
                            <div class="single-img big-img">
                                <div id="carouselExampleIndicators-2" class="carousel slide w-100"
                                     data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div class="container">
                                                <div class="card mb-3 filter shadow mx-auto"
                                                     style="padding: 0%; border: none; width: 100%;">
                                                    <div class="row g-0 py-4">
                                                        <div class="imgSec col-12 col-md-5 d-flex"
                                                             style="position: relative;">
                                                            <div class="imgHero">
                                                                <div class="imageFirst">
                                                                    <a #img
                                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                                        <img
                                                                            src="assets/gallery-1.jpg"
                                                                            class="rounded-10 ms-2 slider-img-card"
                                                                            alt="" style="height: 253px;opacity: 0.5;background: #000"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-7 py-3 px-md-1 px-1">
                                                            <div class="card-body my-auto">
                                                                <h5 class="card-title">Card title</h5>
                                                                <p class="card-text " style="margin-top: 2%;">Lorem
                                                                    ipsum dolor sit amet, consectetur adipiscing
                                                                    elit, sed do eiusmod tempor incididunt ut labore
                                                                    et dolore magna aliqua Egestas purus viverra
                                                                    accumsan in nisl nisi
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="container">
                                                <div class="card mb-3 filter shadow mx-auto"
                                                     style="padding: 0%; border: none; width: 100%;">
                                                    <div class="row g-0 py-4">
                                                        <div class="imgSec col-12 col-md-5 d-flex"
                                                             style="position: relative;">
                                                            <div class="imgHero">
                                                                <div class="imageFirst">
                                                                    <a #img
                                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                                        <img
                                                                            src="assets/gallery-2.jpg"
                                                                            class="rounded-10 ms-2 slider-img-card"
                                                                            alt="" style="height: 253px;opacity: 0.6;"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-7 py-3 px-md-1 px-1">
                                                            <div class="card-body my-auto">
                                                                <h5 class="card-title">Card title</h5>
                                                                <p class="card-text " style="margin-top: 2%;">Lorem
                                                                    ipsum dolor sit amet, consectetur adipiscing
                                                                    elit, sed do eiusmod tempor incididunt ut labore
                                                                    et dolore magna aliqua Egestas purus viverra
                                                                    accumsan in nisl nisi
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="container">
                                                <div class="card mb-3 filter shadow mx-auto"
                                                     style="padding: 0%; border: none; width: 100%;">
                                                    <div class="row g-0 py-4">
                                                        <div class="imgSec col-12 col-md-5 d-flex"
                                                             style="position: relative;">
                                                            <div class="imgHero">
                                                                <div class="imageFirst">
                                                                    <a #img
                                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                                        <img
                                                                            src="assets/gallery-1.jpg"
                                                                            class="rounded-10 ms-2 slider-img-card"
                                                                            alt="" style="height: 253px;"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-7 py-3 px-md-1 px-1">
                                                            <div class="card-body my-auto">
                                                                <h5 class="card-title">Card title</h5>
                                                                <p class="card-text " style="margin-top: 2%;">Lorem
                                                                    ipsum dolor sit amet, consectetur adipiscing
                                                                    elit, sed do eiusmod tempor incididunt ut labore
                                                                    et dolore magna aliqua Egestas purus viverra
                                                                    accumsan in nisl nisi
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-img medium-img">
                                <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                    <img
                                        class="rounded-10 shadow"
                                        style="opacity: 0.3;filter: alpha(opacity=50);"
                                        src="assets/gallery-2.jpg"
                                        alt="">
                                </a>
                            </div>
                            &lt;!&ndash;second section&ndash;&gt;
                            <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-2.jpg"
                                            alt="">
                                    </a>
                                </div>
                            </a>
                            <div class="single-img medium-img">
                                <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                    <img
                                        class="rounded-10 shadow"
                                        src="assets/gallery-2.jpg"
                                        alt="">
                                </a>
                            </div>
                            <div class="single-img medium-img">
                                <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                    <img
                                        class="rounded-10 shadow"
                                        src="assets/gallery-2.jpg"
                                        alt="">
                                </a>
                            </div>
                        </div>
                        <h5 class="mt-50px mb-4" style="color: #3F4096;">The Festival Company 2022</h5>
                        <div class="mt-2">
                            <div class="gallery">
                                &lt;!&ndash;third section&ndash;&gt;
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-2.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img big-img">
                                    <div class="single-img big-img">
                                        <div id="indicator-2" class="carousel slide w-100"
                                             data-bs-ride="carousel">
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <a #img
                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                        <img
                                                            src="assets/gallery-1.jpg"
                                                            class="rounded-10">
                                                    </a>
                                                </div>
                                                <div class="carousel-item">

                                                    <a #img
                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                        <img
                                                            src="assets/gallery-2.jpg"
                                                            class="rounded-10">
                                                    </a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a #img
                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                        <img
                                                            src="assets/gallery-1.jpg"
                                                            class="rounded-10">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                &lt;!&ndash;fourth section&ndash;&gt;
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>

                        <h5 class="mt-50px mb-4" style="color: #3F4096;">The Festival Company 2022</h5>
                        <div class="mt-2">
                            <div class="gallery">

                                &lt;!&ndash;third section&ndash;&gt;
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-2.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img big-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>

                                &lt;!&ndash;fourth section&ndash;&gt;
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container" *ngIf="selectedTab === 3">
                        <h5 class="mt-50px mb-4" style="color: #3F4096;">The Festival Company 2021</h5>
                        <div class="gallery">
                            &lt;!&ndash;first section&ndash;&gt;
                            <div class="single-img big-img">
                                <div id="carouselExampleIndicators-3" class="carousel slide w-100"
                                     data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div class="container">
                                                <div class="card mb-3 filter shadow mx-auto"
                                                     style="padding: 0%; border: none; width: 100%;">
                                                    <div class="row g-0 py-4">
                                                        <div class="imgSec col-12 col-md-5 d-flex"
                                                             style="position: relative;">
                                                            <div class="imgHero">
                                                                <div class="imageFirst">
                                                                    <a #img
                                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                                        <img
                                                                            src="assets/gallery-1.jpg"
                                                                            class="rounded-10 ms-2 slider-img-card"
                                                                            alt="" style="height: 253px;opacity: 0.6;"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-7 py-3 px-md-1 px-1">
                                                            <div class="card-body my-auto">
                                                                <h5 class="card-title">Card title</h5>
                                                                <p class="card-text " style="margin-top: 2%;">Lorem
                                                                    ipsum dolor sit amet, consectetur adipiscing
                                                                    elit, sed do eiusmod tempor incididunt ut labore
                                                                    et dolore magna aliqua Egestas purus viverra
                                                                    accumsan in nisl nisi
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="container">
                                                <div class="card mb-3 filter shadow mx-auto"
                                                     style="padding: 0%; border: none; width: 100%;">
                                                    <div class="row g-0 py-4">
                                                        <div class="imgSec col-12 col-md-5 d-flex"
                                                             style="position: relative;">
                                                            <div class="imgHero">
                                                                <div class="imageFirst">
                                                                    <a #img
                                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                                        <img
                                                                            src="assets/gallery-2.jpg"
                                                                            class="rounded-10 ms-2 slider-img-card"
                                                                            alt="" style="height: 253px;opacity: 0.6;"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-7 py-3 px-md-1 px-1">
                                                            <div class="card-body my-auto">
                                                                <h5 class="card-title">Card title</h5>
                                                                <p class="card-text " style="margin-top: 2%;">Lorem
                                                                    ipsum dolor sit amet, consectetur adipiscing
                                                                    elit, sed do eiusmod tempor incididunt ut labore
                                                                    et dolore magna aliqua Egestas purus viverra
                                                                    accumsan in nisl nisi
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="container">
                                                <div class="card mb-3 filter shadow mx-auto"
                                                     style="padding: 0%; border: none; width: 100%;">
                                                    <div class="row g-0 py-4">
                                                        <div class="imgSec col-12 col-md-5 d-flex"
                                                             style="position: relative;">
                                                            <div class="imgHero">
                                                                <div class="imageFirst">
                                                                    <a #img
                                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                                        <img
                                                                            src="assets/gallery-1.jpg"
                                                                            class="rounded-10 ms-2 slider-img-card"
                                                                            alt="" style="height: 253px;"/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-7 py-3 px-md-1 px-1">
                                                            <div class="card-body my-auto">
                                                                <h5 class="card-title">Card title</h5>
                                                                <p class="card-text " style="margin-top: 2%;">Lorem
                                                                    ipsum dolor sit amet, consectetur adipiscing
                                                                    elit, sed do eiusmod tempor incididunt ut labore
                                                                    et dolore magna aliqua Egestas purus viverra
                                                                    accumsan in nisl nisi
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="single-img medium-img">
                                <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                    <img
                                        class="rounded-10 shadow"
                                        style="opacity: 0.3;filter: alpha(opacity=50);"
                                        src="assets/gallery-2.jpg"
                                        alt="">
                                </a>
                            </div>
                            &lt;!&ndash;second section&ndash;&gt;
                            <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-2.jpg"
                                            alt="">
                                    </a>
                                </div>
                            </a>
                            <div class="single-img medium-img">
                                <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                    <img
                                        class="rounded-10 shadow"
                                        src="assets/gallery-2.jpg"
                                        alt="">
                                </a>
                            </div>
                            <div class="single-img medium-img">
                                <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                    <img
                                        class="rounded-10 shadow"
                                        src="assets/gallery-2.jpg"
                                        alt="">
                                </a>
                            </div>
                        </div>
                        <h5 class="mt-50px mb-4" style="color: #3F4096;">The Festival Company 2021</h5>
                        <div class="mt-2">
                            <div class="gallery">
                                &lt;!&ndash;third section&ndash;&gt;
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-2.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img big-img">
                                    <div class="single-img big-img">
                                        <div id="indicators-4" class="carousel slide w-100"
                                             data-bs-ride="carousel">
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <a #img
                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                        <img
                                                            src="assets/gallery-1.jpg"
                                                            class="rounded-10">
                                                    </a>
                                                </div>
                                                <div class="carousel-item">

                                                    <a #img
                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                        <img
                                                            src="assets/gallery-2.jpg"
                                                            class="rounded-10">
                                                    </a>
                                                </div>
                                                <div class="carousel-item">
                                                    <a #img
                                                       href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                                        <img
                                                            src="assets/gallery-1.jpg"
                                                            class="rounded-10">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                &lt;!&ndash;fourth section&ndash;&gt;
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>

                        <h5 class="mt-50px mb-4" style="color: #3F4096;">The Festival Company 2021</h5>
                        <div class="mt-2">
                            <div class="gallery">

                                &lt;!&ndash;third section&ndash;&gt;
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-2.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img big-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>

                                &lt;!&ndash;fourth section&ndash;&gt;
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                                <div class="single-img medium-img">
                                    <a #img href="https://msinstituteindia.com/img/collabration/images2.jpg">
                                        <img
                                            class="rounded-10 shadow"
                                            src="assets/gallery-1.jpg"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</section>

