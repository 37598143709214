<!-- Start Footer Area -->
<section class="footer-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                   <ul class="footer-contact-info">
                       <li style="padding-left: 0px;">
                           <a routerLink="/"> <img src="assets/img/logo.png" style="max-width: 160px !important;" alt=""></a>
                           <p class="color-white mt-3 " style="text-align: justify;" >
                               We’re not just shaping Industries, we're Fueling Success and Building Futures. With a legacy of
                               innovation and a commitment to excellence, we are your partners in progress, driving growth,
                               where vision meets excellence, and possibilities know no bounds.
                           </p>
                       </li>
                       <li>
                           <i class="flaticon-social-media"></i>
                           <span>Socials Network</span>
                           <ul class="social">
                               <li><a href="https://twitter.com/ms_group_india" target="_blank"><i class="fab fa-x-twitter"></i></a></li>
                               <li><a href="https://www.facebook.com/MS-Group-112259047774216" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                               <li><a href="https://www.instagram.com/ms_group_india/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                               <li><a href="https://www.linkedin.com/company/78297026/admin/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                               <li><a href="https://www.youtube.com/channel/UCTOIEpKkMShaqmb9IIl_cgA" target="_blank"><i class="fab fa-youtube"></i></a></li>
                           </ul>
                       </li>
                   </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5 text-center">
                    <h3>Quick Links</h3>
                    <ul class="footer-quick-links text-center">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/blogs">Blogs</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/careers">Careers</a></li>
                     <!--   <li><a routerLink="/gallery">Gallery</a></li>-->
                        <!--<li><a routerLink="/">Digital</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Shop</a></li>
                        <li><a routerLink="/">Digital</a></li>
                        <li><a routerLink="/">Support</a></li>-->
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-phone-call"></i>
                            <span>Mon to Fri : 10:00AM - 06:00PM</span>
                            <a href="tel:+18008918462">+1800 891 8462</a>
                        </li>
                        <li>
                            <i class="flaticon-email"></i>
                            <span>Do You Have a Question?</span>
                            <a href="mailto:help@msgroupindia.com">hr@msgroupindia.com</a>
                        </li>
                        <li>
                            <i class="flaticon-location"></i>
                            <h6 class="color-white" style="font-size: 20px; line-height: 1.5em">
                                WZ-6, Jail Rd, Lajwanti Garden<br>
                                Janakpuri
                                New - Delhi <br> Delhi - 110046
                            </h6>
                        </li>
                    </ul>
                    <!--<h6 class="color-white" style="font-size: 20px; line-height: 1.5em">
                        WZ-6, Jail Rd, Lajwanti Garden<br>
                        Janakpuri<br>
                        New - Delhi<br>
                        Delhi - 110046
                    </h6>-->
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>Copyright @{{currentYear}} <a target="_blank">MS Group</a>. All rights reserved</p>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/terms-and-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/privacy-and-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer Area -->
