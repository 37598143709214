import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {CareerService} from '../../../shared/services/career.service';
import {FileService} from '../../../shared/services/file.service';
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";

@Component({
    selector: 'app-careers',
    templateUrl: './careers.component.html',
    styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
    title = 'appBootstrap';
    signUpForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    modalType: any;
    fileUploadResumeDoc: any;
    onFileChanged: any;
    closeResult: string = '';
    private ModalDismissReasons: any;
    jobOpenings:any;

    constructor(public modalService: NgbModal,
                public fb: FormBuilder,
                private alertService: AlertService,
                private contactService: ContactUsService,
                private careerService: CareerService,
                private fileService: FileService,
                public router: Router
    ) {
    }

    ngOnInit(): void {
        this.refresh();
        this.getCareerData();
    }

    refresh() {
        this.signUpForm = this.fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'subject': ['', Validators.required],
            'message': ['', Validators.required]
        });
    }

    openApplyModal(content) {
        this.modalType = 'SUBMIT_FORM';
        this.modalService.open(content, {size: 'lg'});
    }
    getCareerData(params: any = {}){
        this.careerService.get(params).subscribe(data => {
            if(data && data.data.length > 0){
                this.jobOpenings = data.data;
            }
        });
    }
    openSuccessModal(content) {
        this.modalType = 'SUCCESS';
        this.modalService.open(content, {size: 'md'});
    }

    goTo(url) {
        window.open(url, '_blank');
    }


    submit(content) {
        this.submitted = true;
        if (!this.signUpForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.signUpForm && this.signUpForm.getRawValue() && this.signUpForm.getRawValue().name) ? this.signUpForm.getRawValue().name : '',
                'email': (this.signUpForm && this.signUpForm.getRawValue() && this.signUpForm.getRawValue().email) ? this.signUpForm.getRawValue().email : '',
                'contact': (this.signUpForm && this.signUpForm.getRawValue() && this.signUpForm.getRawValue().contact) ? this.signUpForm.getRawValue().contact : '',
                'subject': (this.signUpForm && this.signUpForm.getRawValue() && this.signUpForm.getRawValue().subject) ? this.signUpForm.getRawValue().subject : '',
                'message': (this.signUpForm && this.signUpForm.getRawValue() && this.signUpForm.getRawValue().message) ? this.signUpForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.submitted = false;
                this.signUpForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
                this.modalService.open(content ,{size: 'md'});
                this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    goto(data: any = {}) {
        const url= "careers-details/";
        this.router.navigate([url], { queryParams: { id: data.id}});
    }
}
