import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {GalleryComponent} from './components/pages/gallery/gallery.component';
import {CareersDetailsComponent} from "./components/pages/careers/careers-details/careers-details.component";
import {BlogDetailsComponent} from "./components/pages/blog-details/blog-details.component";

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./components/pages/home/home.module').then((m) => m.HomeModule)
    },
    {
        path: 'about',
        loadChildren: () => import('./components/pages/about/about.module').then((m) => m.AboutModule)
    },
    {
        path: 'services',
        loadChildren: () => import('./components/pages/services/services.module').then((m) => m.ServicesModule)
    },
    {
        path: 'blogs',
        loadChildren: () => import('./components/pages/blog/blog.module').then((m) => m.BlogModule)
    },
    {path: 'blogs/:blog-name', component: BlogDetailsComponent},
    {
        path: 'faq',
        loadChildren: () => import('./components/pages/faq/faq.module').then((m) => m.FaqModule)
    },
    {
        path: 'terms-and-conditions',
        loadChildren: () => import('./components/pages/terms-and-conditions/terms-and-conditions.module').then((m) => m.TermsAndConditionsModule)
    },
    {
        path: 'privacy-and-policy',
        loadChildren: () => import('./components/pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule)
    },
    {
        path: 'contact',
        loadChildren: () => import('./components/pages/contact/contact.module').then((m) => m.ContactModule)
    },
    {
        path: 'careers',
        loadChildren: () => import('./components/pages/careers/careers.module').then((m) => m.CareersModule)
    },
    {path: 'careers-details', component: CareersDetailsComponent},

    {
        path: 'education-services',
        loadChildren: () => import('./components/pages/education-services/education-services.module').then((m) => m.EducationServicesModule)
    },
    {
        path: 'healthcare-services',
        loadChildren: () => import('./components/pages/healthcare-services/healthcare-services.module').then((m) => m.HealthcareServicesModule)
    },
    {
        path: 'media-and-marketing-services',
        loadChildren: () => import('./components/pages/media-and-marketing-services/media-and-marketing-services.module').then((m) => m.MediaAndMarketingServicesModule)
    },
    {
        path: 'publishing-house-services',
        loadChildren: () => import('./components/pages/publishing-house-services/publishing-house-services.module').then((m) => m.PublishingHouseServicesModule)
    },
    {
        path: 'gallery',
        component: GalleryComponent
    },
    {
        path: '**',
        loadChildren: () => import('./components/pages/not-found/not-found.module').then((m) => m.NotFoundModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    // imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
