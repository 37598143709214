<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1" style="background-image: url('assets/img/about/about-banner.png'); background-size: cover">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="margin-top: -3%; color: white;">About Us</h2>
                    <ul>
                        <li style="color: white;">Delivering Quality, Earning Trust</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center my-3">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="about-image">
                    <img src="assets/img/about/about.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 ">
                <div class="card p-3" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; border: none;">
                    <span class="cl-blue" style="font-size: 32px; font-weight: 600">Who We Are?</span>
                    <p>
                        MS Group has established its name over years by building trust through our constant dedication
                        and brand management. The MS Group strongly believes that it has a pivotal role to play in
                        shaping our future generations by not only educating the youth of our country but also enabling
                        them to lead a healthy, stress-free, happy and disease free life. We, as a company, make sure
                        that each individual / organization / partner who collaborates with the MS Group family, is
                        delivered the utmost service. We aim to become a ‘people’s company’.
                    </p>
                    <!--<ul class="features-list">
                        <li><span><i class="fas fa-check"></i> Goal Oriented</span></li>
                        <li><span><i class="fas fa-check"></i> Ensuring Quality</span></li>
                        <li><span><i class="fas fa-check"></i> Dedicated Team</span></li>
                        <li><span><i class="fas fa-check"></i> Upskill & Enhance Career</span></li>
                        <li><span><i class="fas fa-check"></i> Authentic Information</span></li>
                        <li><span><i class="fas fa-check"></i> Building Relationships</span></li>
                        <li><span><i class="fas fa-check"></i> Spreading Knowledge</span></li>
                        <li><span><i class="fas fa-check"></i> Creating Awareness</span></li>
                    </ul>-->
                </div>
            </div>
        </div>
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-8 col-md-12 col-sm-12  ">
                    <h3  ><span class="major-color">The Founder’s</span> <span class="cl-blue"> Message</span></h3>
                    <p style="text-align: justify">With a belief that ‘education is the key to the task of nation-building’ M.S. Institute began its
                        journey, in 1999. Within a short span of two decades, our institute achieved great milestones
                        and has
                        even diversified in various sectors including education, publication, media, and healthcare in
                        which we
                        are trying to enhance the lifestyle of people by introducing various wellness products.</p>
                    <p style="text-align: justify">Today M.S. Group of companies stands proudly with our hardworking and experienced teams who have
                        worked
                        creatively, leaving no stone unturned and coming up with solutions to ensure that “Progress
                        Never
                        Stops”.</p>
                    <p style="text-align: justify">Our strength lies in our vision, that is to build a brand that people can trust. M.S. Group of
                        companies
                        consistently delivers quality, credibility, and reliability and its promises and stays true to
                        its value
                        building trust and thus enhancing our brand image. It is also a well-accepted fact that
                        providing the
                        right knowledge and skills to the youth can ensure overall national progress and economic
                        growth. With
                        our prime focus on education, our distance learning solutions, counselling centres, and
                        curriculum-based
                        books have managed to keep students positively motivated and engaged all through the year as
                        Digital
                        platforms came to the rescue as the new way of teaching-learning due to the pandemic.</p>
                    <p>We aim to conduct our businesses ethically and in a manner that is respectful to all.</p>
                    <div class="row ">
                        <div class="col4">
                            <h6 class="text-start cl-blue">(Dr. Pawan Kumar Aggarwal)</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12  text-center m-auto">
                    <img src="assets/img/team/boss.webp"  alt="">
                </div>
            </div>
        </div>

        <!-- journey section-->
       <!-- <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="about-text">
                        <h3>Journey</h3>
                        <img src="assets/img/journey.jpeg"/>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
</section>
<!-- End About Area -->

<!--start our vision area-->
<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center my-5">
            <h2><span class="major-color">Our</span> <span class="cl-blue"> Vision</span></h2>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-3 col-md-6 col-sm-12 text-center">
            <img src="assets/img/vision/ensure_quality.png" alt="">
            <h5 class="pt-4 major-color">Ensuring Quality</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 text-center">
            <img src="assets/img/vision/goal_oriented.png" alt="">
            <h5 class="pt-4 major-color">Goal Oriented</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 text-center">
            <img src="assets/img/vision/upskill_enhancecareer.png" alt="">
            <h5 class="pt-4 major-color">Upskill & Enhance Career</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 text-center">
            <img src="assets/img/vision/build_relationship.png" alt="">
            <h5 class="pt-4 major-color">Building Relationship</h5>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-lg-3 col-md-6 col-sm-12 text-center">
            <img src="assets/img/vision/creating_awareness.png" alt="">
            <h5 class="pt-4 major-color">Creating Awareness</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 text-center">
            <img src="assets/img/vision/dedicated_team.png" alt="">
            <h5 class="pt-4 major-color">Dedicated  Team</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 text-center">
            <img src="assets/img/vision/authentic_information.png" alt="">
            <h5 class="pt-4 major-color">Authentic Information</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 text-center">
            <img src="assets/img/vision/spreading_knowledge.png" alt="">
            <h5 class="pt-4 major-color">Spreading Knowledge</h5>
        </div>
    </div>
</div>
<!--End our vision area-->

<div class="container my-5">
    <div class="row">

    </div>
</div>
<!--Start our value sectiom-->
<div class="container-fluid my-5">
    <div class="row ">
        <div class="col-12">
            <img src="assets/img/about/value.png" class="w-100 h-70 img-fluid"  alt="our values">
        </div>
    </div>
</div>
<!--End our value sectiomn-->

<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-80">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts d-flex ">
                    <img src="assets/img/funfact/stats_projects_icon.png" alt="">
                    <div>
                        <h3 class="align-self-center ms-4"><span class="odometer" data-count="20">00</span><i class="fa-solid fa-plus fa-xs"></i></h3>
                        <p class="text-center ps-4">Products</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts d-flex">
                    <img src="assets/img/funfact/stats_clients_icons.png" style="height: 50%" alt="">
                    <div>
                        <h3 class="align-self-center ms-4"><span class="odometer" data-count="1000">00</span><i class="fa-solid fa-plus fa-xs"></i></h3>
                        <p class="text-center ps-4">Happy Clients</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts d-flex">
                    <img src="assets/img/funfact/stats_awards_icon.png"  alt="">
                    <div>
                        <h3 class="align-self-center ms-4"><span class="odometer" data-count="10">00</span><i class="fa-solid fa-plus fa-xs"></i></h3>
                        <p class="text-center ps-4">Awards</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts d-flex">
                    <img src="assets/img/funfact/stats_expertmember_icons.png"  style="height: 50%" alt="">
                    <div>
                        <h3 class="align-self-center ms-4"><span class="odometer" data-count="200">00</span><i class="fa-solid fa-plus fa-xs"></i></h3>
                        <p class="text-center ps-4">Expert Members</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->

<!--start roadmap image section
<div class="container">
    <div class="row">
        <div class="col-12 text-center mt-5 mb-3">
            <h2><span class="major-color">Our</span> <span class="cl-blue"> Journey</span></h2>
        </div>
    </div>
    <div class="row my-5">
        <div class="col-12">
            <img src="assets/img/about/roadmap.png" class="img-fluid" alt="roadmap image">
        </div>
    </div>
</div>
<!--End roadmap image section-->
