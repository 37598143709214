import { Injectable } from '@angular/core';
import {GlobalService} from "./global.service";
import {AlertService} from "./alert.service";
import {HttpClient} from "@angular/common/http";
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";

@Injectable({
  providedIn: 'root'
})
export class BlogService extends BaseService {

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
      super(http, alertService, globalService);
  }
    get(data): Observable<any> {
        return this.getRequest(AppUrl.BLOG(), data);
    }
    detailblog(title): Observable<any> {
        return this.getRequest(AppUrl.BLOG_DEATILS(title));
    }
}
