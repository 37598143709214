import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../../../shared/services/alert.service";
import {CareerService} from "../../../../shared/services/career.service";
import {FileService} from "../../../../shared/services/file.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-careers-details',
  templateUrl: './careers-details.component.html',
  styleUrls: ['./careers-details.component.scss']
})
export class CareersDetailsComponent implements OnInit {
    id: any;
    url: any;
    title = 'appBootstrap';
    careerForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    modalType: any;
    selectedJob:any;
    fileUploadResumeDoc: any;
    onFileChanged: any;
    data: any;
    closeResult: string = '';
    private ModalDismissReasons: any;
  constructor(private route: ActivatedRoute,
              public modalService: NgbModal,
              public fb: FormBuilder,
              private alertService: AlertService,
              private careerService: CareerService,
              private fileService: FileService,
              public router: Router,
              ) {
      /*if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras != null && this.router.getCurrentNavigation().extras.state != null) {
          this.url = this.router.getCurrentNavigation().extras.state.url;
      }*/
  }

    ngOnInit(): void {
       /* this.route.queryParams.subscribe(params => {
            this.url = params['url'];
        });*/
        this.route.queryParams.subscribe(params => {
            this.id = params.id;
        });
        this.refresh();

        this.jobData();

    }
    refresh() {
        this.careerForm = this.fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'contact': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
            'experience': ['', Validators.required]
        });
    }
    openApplyModal(data ,content) {
        this.modalType = 'SUBMIT_FORM';
        this.data =  data;
        this.modalService.open(content, {size: 'lg'});
    }

    jobData(params: any = {}){
        this.careerService.get(params).subscribe(data => {
            Object.keys(data.data).forEach((key) => {
                if (this.id == data.data[key].id){
                    this.selectedJob = data.data[key];
                }
            });
        });
    }
    openSuccessModal(content) {
        this.modalType = 'SUCCESS';
        this.modalService.open(content, {size: 'md'});
    }

    goTo(url) {
        window.open(url, '_blank');
    }


    submit() {
        this.submitted = true;
        if (!this.careerForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().name) ? this.careerForm.getRawValue().name : '',
                'email': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().email) ? this.careerForm.getRawValue().email : '',
                'contact': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().contact) ? this.careerForm.getRawValue().contact : '',
                'experience': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().experience) ? this.careerForm.getRawValue().experience : '',
                'file_id': (this.fileUploadResumeDoc && this.fileUploadResumeDoc.id) ? this.fileUploadResumeDoc.id : '',
                'applied_for': (this.data && this.data.title) ? this.data.title : ''
            };
            this.careerService.create(params).subscribe(data => {
                this.submitted = false;
                this.careerForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'experience': '',
                });
                this.openSuccessModal(data);
                this.fileUploadResumeDoc = undefined;
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].govt_photo_id_no && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                }
            });
        }
    }

    onFileChange(e, type) {
        this.onFileChanged = e;
        this.fileService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'MSGROUP_RESUME_DOC') {
                this.fileUploadResumeDoc = data['data'];
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });

    }

}
