import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BlogService} from "../../../shared/services/blog.service";

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
    title: any;
    blogsData: any;
    id: any;
    selectedData: any;
    constructor(private route: ActivatedRoute,
                private router: Router,
                private blogServices: BlogService) {
    }
    ngOnInit(): void {
        this.title = this.route.snapshot.params['blog-name'];
        this.refresh();
    }

    refresh() {
        this.detailsblog();
        this.getBlogs();
    }

    getBlogs(params: any = {}) {
        this.blogServices.get(params).subscribe(data => {
            this.blogsData = data;
            // console.log(this.blogsData);
        });
    }

    detailsblog() {
        this.blogServices.detailblog(this.title).subscribe(data => {
            this.selectedData = data;
        });
    }

}
