import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class CareerService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.CAREER(), data);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.JOBOPENING(), data);
    }
    getCareerDataJson() {
        return this.http.get<any>('assets/json/career.json');
    }

    detail(id, data): Observable<any> {
        return this.getRequest(AppUrl.CAREER_DETAILS(id), data);
    }

    getTicketCount(data): Observable<any> {
        return this.getRequest(AppUrl.CAREER_COUNT(), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.CAREER_STATUS(id), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.CAREER(id), data);
    }

}
