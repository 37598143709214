<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1" style="background-image: url('assets/img/about/about-banner.png'); background-size: cover">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white; margin-top: -4%;">Our Blogs</h2>
                    <!--     <ul>
                             <li><a routerLink="/">Home</a></li>
                             <li>Blog Grid</li>
                         </ul>-->
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let blog of blogs">
                <div class="single-blog-post rounded-10 shadow">
                    <div class="post-image">
                        <a (click)="goto('blogs/'+ blog?.url)" ><img
                            class="rounded-10"
                            src="{{blog?.blog_banner?.url}}"  alt="image" style="max-width:100%;"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                        </div>
                        <p>{{blog.short_description}}</p>
                        <a (click)="goto('blogs/'+ blog?.url)"
                           class="read-more-btn">Read More <i
                            class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

          <!--  <div class="col-lg-4 col-md-6">
                <div class="single-blog-post rounded-10 shadow">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-benefits-of-ayurvedic-treatments-and-lifestyles"><img
                            class="rounded-10"
                            src="assets/img/blog-image/blog2.jpg" alt="image" style="max-width:100%;"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            &lt;!&ndash; <ul>
                                 <li>By: <a routerLink="/">James Anderson</a></li>
                                 <li>June 26, 2019</li>
                             </ul>&ndash;&gt;
                        </div>
                      &lt;!&ndash;  <h3><a routerLink="/blogs/blog-benefits-of-ayurvedic-treatments-and-lifestyles">Benefits of Ayurvedic
                            Treatments and Lifestyles</a></h3>&ndash;&gt;
                        <p>In general, Ayurveda is one of the oldest medications in the world.<br></p>
                        <a routerLink="/blogs/blog-benefits-of-ayurvedic-treatments-and-lifestyles" class="read-more-btn">Read
                            More <i
                                class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post rounded-10 shadow">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-why-should-you-opt-for-ayurveda"><img
                            class="rounded-10" src="assets/img/blog-image/blog3.jpg"
                                                                                   alt="image" style="max-width:100%;"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            &lt;!&ndash;  <ul>
                                  <li>By: <a routerLink="/">Steven Smith</a></li>
                                  <li>June 25, 2019</li>
                              </ul>&ndash;&gt;
                        </div>
                       &lt;!&ndash; <h3><a routerLink="/blogs/blog-why-should-you-opt-for-ayurveda">Why Should You Opt for Ayurveda?</a>
                        </h3>&ndash;&gt;
                        <p>Ayurveda is a traditional medicinal practice that engages the individual body, mind and soul.</p>
                        <a routerLink="/blogs/blog-why-should-you-opt-for-ayurveda" class="read-more-btn">Read More <i
                            class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post rounded-10 shadow">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-online-distance-education-a-good-option"><img
                            class="rounded-10"
                            src="assets/img/blog-image/blog4.jpg" alt="image" style="max-width:100%;"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            &lt;!&ndash; <ul>
                                 <li>By: <a routerLink="/">Sarah Taylor</a></li>
                                 <li>June 24, 2019</li>
                             </ul>&ndash;&gt;
                        </div>
                        &lt;!&ndash;<h3><a routerLink="/blogs/blog-online-distance-education-a-good-option">Is Online/Distance Education a
                            Good Option?</a></h3>&ndash;&gt;
                        <p>Digital learning is now a crucial tool for students and educators all over the world.</p>
                        <a routerLink="/blogs/blog-online-distance-education-a-good-option" class="read-more-btn">Read More <i
                            class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post rounded-10 shadow">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-benefits-of-pursuing-undergraduation-or-postgraduation-through-online-distance-education"><img
                            class="rounded-10"
                            src="assets/img/blog-image/blog5.jpg" alt="image" style="max-width:100%;"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            &lt;!&ndash; <ul>
                                 <li>By: <a routerLink="/">James Anderson</a></li>
                                 <li>June 26, 2019</li>
                             </ul>&ndash;&gt;
                        </div>
                       &lt;!&ndash; <h3><a routerLink="/blogs/blog-benefits-of-pursuing-undergraduation-or-postgraduation-through-online-distance-education">Benefits
                            of Pursuing Under Graduation or Post
                            </a>
                        </h3>&ndash;&gt;
                        <p>Distance education at universities has expanded over the years to be one of the</p>
                        <a routerLink="/blogs/blog-benefits-of-pursuing-undergraduation-or-postgraduation-through-online-distance-education"
                           class="read-more-btn">Read More <i
                            class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post rounded-10 shadow">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-convince-and-transform-lives-with-jan-media-component"><img
                            class="rounded-10"
                            src="assets/img/blog-image/blog6.jpg" alt="image" style="max-width:100%;"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            &lt;!&ndash;  <ul>
                                  <li>By: <a routerLink="/">Steven Smith</a></li>
                                  <li>June 25, 2019</li>
                              </ul>&ndash;&gt;
                        </div>
                        &lt;!&ndash;<h3><a routerLink="/blogs/blog-convince-and-transform-lives-with-jan-media-component">Convince and
                            Transform lives with Jan Media</a></h3>&ndash;&gt;
                        <p>As an Indian conglomerate, Jan Media has shown itself in various other industries.</p>
                        <a routerLink="/blogs/blog-convince-and-transform-lives-with-jan-media-component"
                           class="read-more-btn">Read More <i
                            class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post rounded-10 shadow">
                    <div class="post-image">
                        <a routerLink="/blogs/blog-get-an-overview-of-dr-axico-health-campus"><img
                            class="rounded-10"
                            src="assets/img/Axico_Health_Camp.jpg" alt="image" style="max-width:100%;"></a>
                    </div>
                    <div class="post-content">
                        <div class="post-meta">
                            &lt;!&ndash;     <ul>
                                     <li>By: <a routerLink="/">Sarah Taylor</a></li>
                                     <li>June 24, 2019</li>
                                 </ul>&ndash;&gt;
                        </div>
                        &lt;!&ndash;<h3><a routerLink="/blogs/blog-get-an-overview-of-dr-axico-health-campus">Get an overview of Dr. Axico
                            Health camps</a></h3>&ndash;&gt;
                        <p>'Dr. Axico,' is a doctor-to-patient communication platform that allows patients to ask
                            questions</p>
                        <a routerLink="/blogs/blog-get-an-overview-of-dr-axico-health-campus" class="read-more-btn">Read More
                            <i
                                class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
            -->
        </div>
    </div>
</section>
<!-- End Blog Area -->
