import {Component, OnInit} from '@angular/core';
import {BlogService} from "../../../shared/services/blog.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    blogs: any;
    constructor(private  blogData: BlogService, public router: Router) {
    }

    ngOnInit(): void {
        this.get();
    }

    get(params: any = {}) {
        this.blogs = [];
        this.blogData.get(params).subscribe(data => {
            if (data && data.data.length > 0) {
                this.blogs = data.data;

            }
        });
    }
    goTo(url) {
        window.open(url, '_blank');
    }
    gotoblog(url: any) {
        // this.router.navigate([url], { state: data});
        this.router.navigateByUrl(url);
    }

}
