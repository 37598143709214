import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {BlogService} from "../../../shared/services/blog.service";

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
    blogs: any;
    constructor(private  blogData: BlogService, public router: Router) {
    }

    ngOnInit() {
        this.get();
    }
    get(params: any = {}) {
        this.blogs = [];
        this.blogData.get(params).subscribe(data => {
            if (data && data.data.length > 0) {
                this.blogs = data.data;

            }
        });
    }
    goto(url: any) {
        // this.router.navigate([url], { state: data});
        this.router.navigateByUrl(url);
    }

}
